import Home from "./pages/home/Home";
import Contact from "./pages/contact/Contact";
import Legality from "./pages/legality/Legality";
import NotFound from "./pages/notFound/NotFound";
import { Routes, Route } from "react-router-dom";
const Pages = [
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/contact",
    element: <Contact />,
  },
  {
    path: "/legality",
    element: <Legality />,
  },
  {
    path: "*",
    element: <NotFound />,
  },
];
const PageRouter = () => {
  return (
    <Routes>
      {Pages.map((page) => (
        <Route path={page.path} element={page.element} />
      ))}
    </Routes>
  );
};

export default PageRouter;
