import { useState, useEffect } from "react";
import Highlight from "../../components/Hightlight";

const Contact = () => {
  const [isCopiedPopup, setIsCopiedPopup] = useState(false);

  useEffect(() => {
    let timer: any;
    if (isCopiedPopup) {
      timer = setTimeout(() => {
        setIsCopiedPopup(false);
      }, 1000);
    }
    return () => clearTimeout(timer);
  }, [isCopiedPopup]);

  return (
    <div className=" flex flex-col items-center md:justify-center h-screen py-[20%] md:py-[5%] fade-in mx-[5%]">
      <h1 className="text-xl md:text-3xl font-bold">Contact</h1>
      <div className="md:text-xl">
        <div>
          reach us out at{" "}
          <span
            id="click-to-copy"
            onClick={(e) => {
              navigator.clipboard.writeText("office@birawakumalakapital.com");

              setIsCopiedPopup(true);
            }}
            className="hover:text-slate-600 cursor-pointer"
          >
            <Highlight>office@birawakumalakapital.com</Highlight>
          </span>
        </div>
        <div>
          or through WhatsApp at{" "}
          <span
            id="click-to-copy"
            onClick={(e) => {
              navigator.clipboard.writeText("+6281221892288");
              setIsCopiedPopup(true);
            }}
            className="hover:text-slate-600 cursor-pointer"
          >
            <Highlight>+62 812 2189 2288</Highlight>
          </span>
        </div>
        <div className="mt-4 h-[50px]">
          <div
            className={`text-slate-500 text-center duration-200 ease-in-out
            ${isCopiedPopup ? "opacity-100" : "opacity-0"}
        `}
          >
            copied
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
