import Highlight from "../../components/Hightlight";
const Home = () => {
  return (
    <div className="fade-in py-[15%] md:py-[5%] px-[5%] flex flex-col md:items-center justify-center" id="general-container">
      <div className="max-w-6xl">
        <div id="hero-container" className=" md:h-[75vh] flex flex-col md:items-center justify-center mb-10">
          <div id="title" className="text-xl md:text-5xl my-3">
            Birawa Kumala Kapital
          </div>
          <div className="bg-black w-1/4 h-[2px] md:h-[8px] mb-6" id="line" />
          <div id="simple-explanation" className="md:text-xl md:w-3/4 w-11/12">
            With various experiences in the world of business and commerce, PT. BIRAWA KUMALA KAPITAL develops itself in the general field and takes a special part in the Oil and Gas sector. We hope that through this company profile we can
            provide real and accurate information about our existence and development, so that we can be better known in the business and industrial world
          </div>
        </div>
        <div id="vision-mission-container" className="mt-[100px] md:h-[75vh] flex flex-col md:items-center justify-center mb-10  md:text-xl">
          <div id="vision">
            <div id="title">Our Vision</div>
            <div id="line" className="w-[50px] border-b-2 border-b-black"></div>
            <div className="md:w-1/3">
              <Highlight>To become a leading oil and gas trading</Highlight> and distribution company in Indonesia with sustainable growth achieved through professionalism and concern for employees, society, and the environment.
            </div>
          </div>
          <div className="h-12" />
          <div id="mission" className=" text-right flex flex-col items-end py-5">
            <div id="title">Our Mission</div>
            <div id="line" className="w-[50px] border-b-2 border-b-black"></div>
            <div className=" md:w-1/3">
              Improve operational performance excellence to <Highlight>serve customers with value and high quality products and services</Highlight>. Develop employee competencies, systems, and efficient infrastructure in culture,
              innovation, integrity, care, and synergy within the company.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Home;
