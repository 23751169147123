import { Link, useLocation } from "react-router-dom";
// import logo from public
const links = [
  { to: "/", text: "Home" },
  { to: "/contact", text: "Contact" },
  { to: "/legality", text: "Legality" },
];

const NavigationBar = () => {
  // get current path
  const location = useLocation();
  return (
    <div className=" z-10 absolute w-screen pt-2  fade-in flex flex-col items-center px-5" id="link-and-line">
      <div id="link-and-logo" className="flex content-between pb-2 items-center justify-between w-[90%]">
        <img src="/logo-bg-removed.png" className="h-[15px] md:h-[30px]" />
        <div id="links" className="flex justify-center">
          {links.map((link) => (
            <div className={`mx-5 font-medium md:text-lg  flex hover:text-black  ${location.pathname === link.to ? "text-black" : "text-slate-500"}`}>
              <Link to={link.to}>{link.text}</Link>
            </div>
          ))}
        </div>
        <img src="/logo-bg-removed.png" className="h-[30px] opacity-0" />
      </div>
      <div id="line" className="border-t-2 w-[90%] border-slate-800 " />
    </div>
  );
};
export default NavigationBar;
