import ReactDOM from "react-dom/client";
import NavigationBar from "./components/NavigationBar";
import "./index.css";
import PageRouter from "./PageRouter";
import { BrowserRouter } from "react-router-dom";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <div className="h-screen">
    <BrowserRouter>
      <div className="fade-in">
        <NavigationBar />
        <PageRouter />
      </div>
    </BrowserRouter>
  </div>
);
