const Legality = () => {
  return (
    <div className="flex flex-col items-center justify-center py-[20%] md:py-[5%] fade-in mx-[5%]">
      <h1 className="text-xl md:text-3xl font-bold">Legality</h1>
      <div>
        <br />

        <p>
          Akta pendirian PT. BIRAWA KUMALA KAPITAL
          <br />
          Nomor : 18
          <br />
          Tanggal : 12 April 2018
          <br />
          Nama Notaris : H.WARMAN,SH
          <br />
        </p>
        <br />
        <br />

        <p>
          SK Kehakiman pendirian PT. BIRAWA KUMALA KAPITAL
          <br />
          Nomor : AHU-0020905.AH.01.01.TAHUN 2018
          <br />
          Tanggal : 19 April 2018
        </p>

        <p>
          Akta Perubahan terakhir
          <br />
          PT. BIRAWA KUMALA KAPITAL
          <br />
          Nomor : 46
          <br />
          Tanggal : 23 Juni 2020
          <br />
          Nama Notaris : EKA ASTRI MAERISA, SH.,M.Kn
        </p>
        <br />
        <br />

        <p>
          SK Kehakiman Perubahan terakhir PT. BIRAWA KUMALA KAPITAL
          <br />
          Nomor : AHU-0043946.AH.01.02.TAHUN 2020
          <br />
          Tanggal : 29 Juni 2020
        </p>
        <br />
        <br />

        <p>
          {" "}
          Nomor Pokok Wajib Pajak ( NPWP )
          <br />
          Nomor : 84.840.359.8-043.000
        </p>

        <p>
          Surat Keterangan Terdaftar ( SKT )
          <br />
          Nomor : S-2585KT/ WPJ.21/KP.0503/2018
          <br />
          Tanggal Terdaftar : 14 Mei 2018
        </p>
      </div>
    </div>
  );
};

export default Legality;
